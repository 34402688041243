@import url('https://fonts.googleapis.com/css?family=Cairo&display=swap');

body {
    margin: 0;
    font-family: "Cairo", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

    .ant-form-inline .ant-form-item {
        display: block;
    }

body > iframe[style*='2147483647']{
  display: none;
}

svg { vertical-align: baseline; }

.select-overflow {
  text-overflow: ellipsis;
  white-space: 'pre-wrap';
}

.h-400 {
  height: 400px;
}

.h-700 {
  height: 700px
}

.editable-row:hover .editable-cell-value {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.editable-cell-value {
  padding: 5px 12px;
  cursor: pointer;
}

.transfer-table .ant-table-placeholder {
  display: none;
}

.popover-item:hover {
  background-color: #F5F5F5;
}

.ant-custom-popover-inner-content .ant-popover-inner-content  {
  padding: 8px; /* Remove all padding */
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}